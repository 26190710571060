import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { transformImages } from 'helpers/transformImages';
import withUsabilityTestingTemplate from 'hoc/content-cluster';
import Link from 'components/ui/link';
import Img from 'gatsby-image';
import arrow from 'img/ui/arrow_white.svg';

const GeneratingHeatMaps = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query GeneratingHeatMaps {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "heatmaps/generating-heat-maps" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(quality: 95) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  const images = transformImages(edges);
  return (
    <>
      <h3>1. Establish which pages you need to set up your heat map on</h3>

      <p>
        Although it might be tempting to simply create heat maps for each page, we recommend heat
        map creators to focus first on those that are crucial for their organization. These include
        your{' '}
        <Link
          href="https://instapage.com/blog/landing-page-heat-map"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          homepage
        </Link>{' '}
        (which is often the first page that users visit) as well as other pages that are of high
        importance for your business, such as pricing. If you have an eCommerce store or campaign
        pages for promotions or giveaways, it’s also worth setting up a heatmap on them, as they
        need to be user-friendly and easy to navigate.{' '}
      </p>

      <p>
        Another place worth considering are newly launched pages. They require special attention –
        any design elements that need refining should be identified either before or shortly after
        publishing. If the page features call-to-action (CTA) elements, they also need to be tested
        to determine if they are placed correctly and can generate interaction.
      </p>

      <p>
        Heat maps can also be used to target pages with high bounce rates and those that have low
        click-through rates (CTR). Pages that do not yield their expected conversions need extra
        attention, and using your Google Analytics dashboard should enable you to identify which
        pages are not performing well.
      </p>
      <Img
        fluid={images.analytics.node.childImageSharp.fluid}
        alt="Google Analytics - LiveSession website"
        title="Establish which pages you need to set up your heat map on"
      />

      <p>
        Last, but not least, remember to also review conversion paths, i.e.,{' '}
        <Link href="/user-experience/user-journey-map/" target="_blank" rel="noopener noreferrer">
          user journeys
        </Link>{' '}
        that show how your users navigate your website from first entry up to placing an order or
        taking another desired action. You need to use your heat map data to optimize these
        processes and ensure that these visitor paths are error-free and seamless.{' '}
      </p>
      <h3>2. Decide which information you need to verify</h3>

      <p>
        Before you launch your heat map, it’s worth agreeing on the problems or issues you’re aiming
        to tackle. If you, for instance, have a big drop-off rate for one or more of your product
        pages, making a heat map will enable you to identify where users end their journey. You can
        then further investigate the issue by using other tools such as session recordings or
        surveys – we will talk about them later in this article.{' '}
      </p>

      <h3>3. Use various types of heat maps (or establish which one you need the most)</h3>

      <p>
        Once you determine which areas of your website call for improvement, such as in the
        above-mentioned example of product pages, you might uncover that you’d benefit from more
        than one heat map type. For a comprehensive overview, you can refer to our dedicated piece
        which reviews{' '}
        <Link href="/heatmaps/what-is-a-heatmap/" target="_blank" rel="noopener noreferrer">
          all the heatmap options
        </Link>{' '}
        you can choose from.
      </p>

      <p>
        For instance, using a scroll map and a click map together will help you to determine how far
        down a page users scroll and which areas generate clicks and interest.
      </p>

      <p>
        Ultimately, before deciding on the heat map type, aim to establish which heat map data you
        need the most to resolve any usability problems and improve on-site conversion.
      </p>
      <h3>4. Use jointly with session recordings and eye-tracking software</h3>

      <p>
        Enhancing your website’s user experience with heat map data, which can illuminate all
        on-site interactions, can give you the edge you need to cultivate exceptional experiences.
        However, bear in mind that heat maps let you see the bulk of user activity on each site and
        you might need other tools to dive deep into each user’s on-site actions. Here’s where
        session recording solutions come into play, giving you the option to watch a video of how
        each user moves and interacts with your website. To make the most of your{' '}
        <Link href="/heatmaps/heatmap-analysis/" target="_blank" rel="noopener noreferrer">
          heat map analysis
        </Link>
        , we recommend using a solution like{' '}
        <Link href="/help/how-do-the-clickmaps-work/" target="_blank" rel="noopener noreferrer">
          LiveSession
        </Link>
        , which lets you use both heatmaps and session recordings.
      </p>

      <Img
        fluid={images.heatmaps.node.childImageSharp.fluid}
        alt="Heatmap and session recording"
        title="Use jointly with session recordings and eye-tracking software"
      />

      <p>
        {' '}
        Also, if you’d like to understand which parts of your website are most and least looked at,
        consider using eye-tracking software to create hover maps. As it requires a wearable
        equipment, however, it is most commonly used in a lab environment as part of usability
        testing sessions. We discuss them in the next point.
      </p>
      <h3>5. Verify your findings with other tools before drawing any conclusions</h3>

      <p>
        Once you’ve made your heat maps, it is important not to treat the data you gathered as
        final. Cross-check your findings with tools such as Google Analytics and, where possible,
        run website surveys to gather more information.
      </p>

      <p>
        Additionally, you can take advantage of user testing labs where participants are asked to
        complete a specific task. Depending on your preference, you can either sit back and quietly
        observe them or ask testers to comment on each step they take. Note: consider conducting
        anonymized user sessions, as some respondents might not be comfortable providing feedback
        openly.
      </p>

      <p>
        Now that you know how to create a heat map, let’s take a look at the mistakes that you need
        to avoid.{' '}
      </p>

      <h2>Mistakes to avoid when you generate a heat map</h2>
      <h3>1. Don’t draw conclusions too early</h3>

      <p>
        When making heat maps, it can be tempting to gather data as fast as possible so you can go
        straight to implementing solutions. However, it is important that you do not rush the
        process. Not only do you require enough website visitors to get credible insights but you
        also need to set realistic deadlines for data gathering – right from the very start. This
        will ensure you get the answers you need without the risk of jumping to wrong conclusions.
      </p>
      <h3> 2. Not supporting your heat map findings with user feedback</h3>

      <p>
        As mentioned earlier, not only does heat map data give you a bird’s eye view of all user
        actions. More often than not, they are also anonymous, which means you don’t have the option
        to collect qualitative user feedback. Namely, you can’t reach out to specific users and ask
        them for a more detailed explanation of their actions.
      </p>

      <p>
        You can tackle this potential obstacle with user testing sessions, which you can use to ask
        testers about your website content and understand which areas you must further focus on. You
        can do so by either talking to testers directly or asking them to finalize the session with
        a survey where they provide feedback.
      </p>

      <p>
        Last, but not least, if you decide to organize user testing sessions, remember to account
        for any visual impairment or website responsiveness issues – these are the usability areas
        which heat map analysis might not clearly point you to.
      </p>
      <h3>3. Forgetting to block your IPs</h3>

      <p>
        When generating heat map data, you need to ensure that the data you gather is from website
        users who are not your employees. Not blocking IPs will mean you could be gathering data
        from your own company, which is far from ideal – especially when testing newly launched
        pages that are often viewed by in-house testing teams or other colleagues.
      </p>

      <Img
        fluid={images.office.node.childImageSharp.fluid}
        alt="how to make a heat map and make sure your data is relevant – blocking internal traffic"
        title="Forgetting to block your IPs"
      />

      <p>
        Source:{' '}
        <Link
          href="https://unsplash.com/photos/5QiGvmyJTsc"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Unsplash
        </Link>
      </p>

      <p>
        So, always double-check if internal traffic is not included in your analysis to ensure that
        your data is credible.
      </p>
      <h3>4. Lack of user context</h3>

      <p>
        No two web users are alike, which is why you need to consider your various customer personas
        with their own challenges, goals, pain points, and on-site behavior. Some customers, for
        instance, are interacting with your website for the first time, while others are returning
        visitors.
      </p>

      <p>
        Consider splitting up your testing to reflect these different experiences. We recommend
        analyzing how new customers interact with your website, product, and services pages
        separately from those who already know it well and are more comfortable with the given
        information.
      </p>

      <p>
        However, if both your new user and established customer groups are struggling to finalize
        certain processes, product or interface familiarity won’t be to blame. There is the
        likelihood of a wider issue with the UX, which needs to be addressed promptly.
      </p>
      <h3>5. Treating the number of spotted actions as an indicator of success</h3>

      <p>
        Not all repeat actions indicate success. Consider, for instance, “rage clicks”, which are
        error clicks that users perform when clicking on content repeatedly as they are unable to
        move successfully further down their user journey.
      </p>
      <Img
        fluid={images.bug.node.childImageSharp.fluid}
        alt="generate heat map to make sure you spot bugs and errors"
        title="Treating the number of spotted actions as an indicator of success"
      />
      <p>
        {' '}
        Sometimes, users also navigate back to a webpage on more than one occasion or click on
        elements such as CTA buttons several times. It doesn’t necessarily mean interest in certain
        content. Especially, if your conversion rate numbers simply don’t add up to the number of
        interactions.
      </p>

      <p>
        If that’s something you notice in your data, you should make sure the buttons are working
        properly before making any design changes.{' '}
      </p>
      <h2>How to create a heat map – final thoughts </h2>
      <p>
        Generating heat maps can provide an exceptional source of data to enhance your digital
        presence. Always retain your focus on the data you require, augment your findings with
        additional tools and user testing, and be clear on what your picture of success looks like
        for user engagement. It could be the game-changer you need to boost your business and gain
        customers!{' '}
      </p>
      <p>
        If you’re searching for a tool that offers both heat maps and session recordings, then look
        no further and check out{' '}
        <Link href="/" target="_blank" rel="noopener noreferrer">
          LiveSession
        </Link>{' '}
        – we offer a free trial.
      </p>
      <div className="next-chapter">
        <Link href="/heatmaps/heat-maps-for-website/">
          <div>
            <p>Chapter 3</p>
            <p>How to use heat maps to improve your website</p>
          </div>
          <img src={arrow} alt="arrow" title="white arrow" />
        </Link>
      </div>
    </>
  );
};

export default withUsabilityTestingTemplate(GeneratingHeatMaps);
